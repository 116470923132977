.iconSmall{
    font-size: 1.2rem;
}

.iconMedium{
    font-size: 1.5rem;
}

.iconLarge{
    font-size: 2rem;
}

.itemUser {
    padding: 5px 15px;
    border-bottom: #ecf0f1 solid 1px;
}

.itemUser:hover {
    background-color: #f8f8f9;
    transition: 0.5s;
}