.text-left {
  text-align: left!important;
}

.text-center {
  text-align: center;
}

h1, h2, h3, h4, h5, h6 {
  /* margin: 0px; */
  /* padding: 0px; */
  font-weight: 550;
}

.text-primary {
  color: #4791db;
}

.text-secondary {
  color: #424242
}

.text-error {
  color: #e57373
}

.text-warning {
  color: #ffb74d
}

.text-info {
  color: #64b5f6
}

.text-success {
  color: #81c784
}

.bg-primary {
  background-color: #4791db;
}

.bg-secondary {
  color: #424242
}

.bg-error {
  color: #e57373
}

.bg-warning {
  color: #ffb74d
}

.bg-info {
  color: #64b5f6
}

.bg-success {
  color: #81c784
}

.fontSize17 {
  font-size: 17px;
}

.m-auto {
  margin: auto
}

.p-1 {
  padding: .25rem!important
}

.pt-1, .py-1 {
  padding-top: .25rem!important
}

.pr-1, .px-1 {
  padding-right: .25rem!important
}

.pb-1, .py-1 {
  padding-bottom: .25rem!important
}

.pl-1, .px-1 {
  padding-left: .25rem!important
}

.p-2 {
  padding: .5rem!important
}

.pt-2, .py-2 {
  padding-top: .5rem!important
}

.pr-2, .px-2 {
  padding-right: .5rem!important
}

.pb-2, .py-2 {
  padding-bottom: .5rem!important
}

.pl-2, .px-2 {
  padding-left: .5rem!important
}

.p-3 {
  padding: 1rem!important
}

.pt-3, .py-3 {
  padding-top: 1rem!important
}

.pr-3, .px-3 {
  padding-right: 1rem!important
}

.pb-3, .py-3 {
  padding-bottom: 1rem!important
}

.pl-3, .px-3 {
  padding-left: 1rem!important
}

.p-4 {
  padding: 1.5rem!important
}

.pt-4, .py-4 {
  padding-top: 1.5rem!important
}

.pr-4, .px-4 {
  padding-right: 1.5rem!important
}

.pb-4, .py-4 {
  padding-bottom: 1.5rem!important
}

.pl-4, .px-4 {
  padding-left: 1.5rem!important
}

.p-5 {
  padding: 3rem!important
}

.pt-5, .py-5 {
  padding-top: 3rem!important
}

.pr-5, .px-5 {
  padding-right: 3rem!important
}

.pb-5, .py-5 {
  padding-bottom: 3rem!important
}

.pl-5, .px-5 {
  padding-left: 3rem!important
}

.m-n1 {
  margin: -.25rem!important
}

.mt-n1, .my-n1 {
  margin-top: -.25rem!important
}

.mr-n1, .mx-n1 {
  margin-right: -.25rem!important
}

.mb-n1, .my-n1 {
  margin-bottom: -.25rem!important
}

.ml-n1, .mx-n1 {
  margin-left: -.25rem!important
}

.m-n2 {
  margin: -.5rem!important
}

.mt-n2, .my-n2 {
  margin-top: -.5rem!important
}

.mr-n2, .mx-n2 {
  margin-right: -.5rem!important
}

.mb-n2, .my-n2 {
  margin-bottom: -.5rem!important
}

.ml-n2, .mx-n2 {
  margin-left: -.5rem!important
}

.m-n3 {
  margin: -1rem!important
}

.mt-n3, .my-n3 {
  margin-top: -1rem!important
}

.mr-n3, .mx-n3 {
  margin-right: -1rem!important
}

.mb-n3, .my-n3 {
  margin-bottom: -1rem!important
}

.ml-n3, .mx-n3 {
  margin-left: -1rem!important
}

.m-n4 {
  margin: -1.5rem!important
}

.mt-n4, .my-n4 {
  margin-top: -1.5rem!important
}

.mr-n4, .mx-n4 {
  margin-right: -1.5rem!important
}

.mb-n4, .my-n4 {
  margin-bottom: -1.5rem!important
}

.ml-n4, .mx-n4 {
  margin-left: -1.5rem!important
}

.m-n5 {
  margin: -3rem!important
}

.mt-5, .my-5 {
  margin-top: 3rem!important
}

.mr-5, .mx-5 {
  margin-right: 3rem!important
}

.mb-5, .my-5 {
  margin-bottom: 3rem!important
}

.ml-5, .mx-5 {
  margin-left: 3rem!important
}

.rounded-circle {
  border-radius: 50%;
}

.none {
  display: none;
}

.displayBlock {
  display: block;
}

.border {
  border: 1px solid #fff
}